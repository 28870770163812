
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import { DialogsContainer } from '@/containers/dialogs';
import 'simplebar-react/dist/simplebar.min.css';
import { ProgressBarContainer } from '@/containers/progressBar';
import { Routes } from '@/layouts';
import { CreateClientOptions } from '@/lib/apollo';
import ApolloProviderWrapper from '@/lib/ApolloProviderWrapper';
import { AuthProvider } from '@/providers/auth';
import { SettingsProvider } from '@/providers/settings';
import env from '@beam-australia/react-env';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { ConfigProvider } from 'antd';
import locale from 'antd/locale/vi_VN';
import useTranslation from 'next-translate/useTranslation';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { relayStylePagination } from '@apollo/client/utilities';
import { useMemo } from 'react';
import Guard from '../app/Guard';
import NotistackProvider from '../providers/NotistackProvider';
import ThemeConfigProvider from '../theme';
import GlobalStyles from '../theme/globalStyles';
import createEmotionCache from '../utils/createEmotionCache';
import '@/theme/global.css';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const typePolicies = {
  writing_check_points: {
    keyFields: ['checkPointId'],
  },
  speaking_check_points: {
    keyFields: ['checkPointId'],
  },
  speaking_parts: {
    fields: {
      questions: {
        merge: false,
      },
    },
  },
  speaking_move_objects: {
    fields: {
      objectPlaceholders: {
        merge: false,
      },
    },
  },
  Query: {
    fields: {
      orderCodes: {
        keyArgs: ['filterBy'],
        merge: (_: any, incoming: any) => {
          return incoming;
        },
      },
      adminSchools: relayStylePagination(['filterBy']),
      adminTopics: relayStylePagination(['filterBy']),
    },
  },
};

const uri = env('GRAPHQL_API');
const uriV2 = env('GRAPHQL_API_V2');
const adminSecret = env('HASURA_ADMIN_SECRET');

function getHeaders() {
  const headers: Record<string, string> = {};
  headers['x-hasura-admin-secret'] = adminSecret;
  return headers;
}

function App<P>({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: AppProps<P> & { emotionCache?: EmotionCache }): JSX.Element {
  const { t } = useTranslation('title');

  const apolloOptions: CreateClientOptions = useMemo(
    () => ({
      uri,
      uriV2,
      getHeaders,
      typePolicies,
    }),
    [],
  );

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <title>{t('default')}</title>
      </Head>

      <Script src="/__ENV.js" strategy="beforeInteractive" />

      <ConfigProvider
        locale={locale}
        theme={{
          token: {
            colorPrimary: '#00AB55',
            // colorBgBase: '#212B36',
            // colorBgContainer: '#212B36',
            // controlHeight: 45,
            // colorBorder: '#636b74',
            // colorPrimaryHover: '#00AB55',
            // colorPrimaryActive: '#00AB55',
            // colorPrimaryText: '#00AB55',
          },
          // algorithm: theme.darkAlgorithm,
        }}
      >
        <SettingsProvider>
          <CacheProvider value={emotionCache}>
            <ThemeConfigProvider>
              <NotistackProvider>
                <GlobalStyles />
                <ApolloProviderWrapper options={apolloOptions} initialState={pageProps}>
                  <AuthProvider>
                    <ProgressBarContainer />
                    <DialogsContainer />
                    <Guard>
                      <Routes>
                        <Component {...pageProps} />
                      </Routes>
                    </Guard>
                  </AuthProvider>
                </ApolloProviderWrapper>
              </NotistackProvider>
            </ThemeConfigProvider>
          </CacheProvider>
        </SettingsProvider>
      </ConfigProvider>
    </>
  );
}

const __Page_Next_Translate__ = App;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  